html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
html, body {
  height: 100%;
  background: "#fff"
}
body {
  /* margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  width: 100%;
  /* font-family: 'alternate-gothic-no-2-d', sans-serif; */
  font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
  color: #001828;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @font-face { font-family: alternate-gothic-no-2-d; src: url(./fonts/agn2d.ttf); } */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0 0 32px;
  font-size: 22px;
  line-height: 32px;
}

h1, h4, h6, p {
  font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
}

h5 {
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
}

b, strong {
  font-weight: bolder;
}

* {
  box-sizing: border-box;
}

img {
  border-style: none;
}

a {
  text-decoration: none;
}

.btn, input[type='submit'], .block.book .formkit-input[type='submit'], .formkit-submit {
  display: inline-block;
  padding: 12px 24px 6px;
  font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s;
}

.btn-primary, input[type='submit'], .block.book .formkit-input[type='submit'] {
  background-color: #f26111;
  transition: all 0.3s;
}